import LoadingScreen from "components/LoadingScreen";
import { createContext, ReactNode, useEffect, useReducer } from "react";
import axios from "../utils/axios";
import { parseRes } from "../utils/utils";
import { URI, AccessTokenConst, RefreshTokenConst } from "../constants";
import { Customer, User } from "../sk.types";
// --------------------------------------------------------
type AuthUser = null | Record<string, any>;

type InitialAuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  passwordReset: boolean;
};

// props type
type AuthProviderProps = { children: ReactNode };
// --------------------------------------------------------

const initialState: InitialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  passwordReset: false,
};

const setSession = (
  accessToken: string | null,
  refreshToken: string | null
) => {
  if (accessToken) {
    localStorage.setItem(AccessTokenConst, accessToken);
    if (refreshToken) {
      localStorage.setItem(RefreshTokenConst, refreshToken);
    }
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(AccessTokenConst);
    localStorage.removeItem(RefreshTokenConst);
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: InitialAuthState, action: any) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
        passwordReset: action.payload.passwordReset,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        passwordReset: action.payload.passwordReset,
      };
    }
    case "PASSWORD_CHANGED": {
      return {
        ...state,
        passwordReset: false,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        passwordReset: false,
      };
    }
    case "REGISTER": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        passwordReset: action.payload.passwordReset,
      };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => {},
  login: (uid: string, password: string) => Promise.resolve(),
  getUser: () => Object(),
  getCustomer: () => Object(),
  passwordChanged: () => {},
});

export const JWTAuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (uid: string, password: string) => {
    const { data } = await axios.post(URI.LOGIN, { uid, password });
    setSession(data.accessToken, data.refreshToken);
    dispatch({
      type: "LOGIN",
      payload: { user: data.user, passwordReset: data.password_reset },
    });
  };
  const logout = () => {
    setSession(null, null);
    dispatch({ type: "LOGOUT" });
  };

  const getUser = (): User => {
    return parseRes(state.user);
  };

  const getCustomer = (): Customer | null => {
    const user = getUser();
    if (user?.customer) return user?.customer;
    return null;
  };

  const passwordChanged = () => {
    dispatch({ type: "PASSWORD_CHANGED" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem(AccessTokenConst);
        if (accessToken) {
          setSession(accessToken, null);
          const { data } = await axios.get(URI.PROFILE);
          const resData = data.data;
          dispatch({
            type: "INIT",
            payload: {
              user: resData,
              isAuthenticated: true,
              passwordReset: resData.attributes?.password_reset,
            },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: {
              user: null,
              isAuthenticated: false,
              passwordReset: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: { user: null, isAuthenticated: false, passwordReset: false },
        });
      }
    })();
  }, []);

  // show loading until not initialized
  if (!state.isInitialized) <LoadingScreen />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        getUser,
        getCustomer,
        passwordChanged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
