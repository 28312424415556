import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      smartKontor: "Смарт Контор",
      hello: "Сайн уу? ",
      userNumber: "ХД: {{uid}} - {{name}}",
      ownerName: "Эзэмшигч: {{name}}",
      customerNumber: "ХД: {{uid}}",
      connectCustomer: "Холбох",
      enterCustomerNumber: "Хэрэглэгчийн дугаар",
      email: "Имэйл хаяг",
      loginName: "Нэвтрэх нэр",
      rememberMe: "Намайг сана",
      signIn: "Нэвтрэх",
      logout: "Гарах",
      send: "Илгээх",
      back: "Буцах",
      remove: "Устгах",
      save: "Хадгалах",
      clear: "Цэвэрлэх",
      close: "Хаах",
      password: "Нууц үг",
      nodata: "Мэдээлэл олдсонгүй",
      forgotPassword: "Нууц үг мартсан?",
      resetPassword: "Нууц үг сэргээх",
      passwordChangeInfo: "Нууц үг:",
      currentPassword: "Одоогийн нууц үг",
      newPassword: "Шинэ нууц үг",
      confirmPassword: "Шинэ нууц үг давт",
      passwordRequirementsAreMet: "Эдгээр шаардлагыг хангасан эсэхийг шалгаарай",
      passwordDoesntmatched: "Баталгаажуулах нууц үг шинэ нууц үгтэй таарахгүй байна",
      copied: "Хуулагдлаа",
      pleaseLoginForShowDetails: "Та төлбөрийн задаргааны утгыг харахын тулд 'Нэвтрэх' товчин дээр дарна уу.",
      menuIsRepairing: "Энэ цэс засвартай байна",
      passwordRequirements:{
        minimumCharactersLong: "Нууц үг багадаа 8 тэмдэгтээс бүрдэнэ",
        atLeastOneLowercaseCharacter: "Дор хаяж 1 жижиг үсэг байна",
        atLeastOneUppercaseCharacter: "Дор хаяж 1 том үсэг байна",
      },
      menu:{
        dashboard: 'Удирдлагын хяналт',
        invoices: 'Төлбөрийн мэдээлэл',
        onlinePayment: 'Онлайн гүйлгээ',
        counterInfo: 'Тоолуурын мэдээлэл',
        waterInfo: 'Усны заалт',
        callingInfo: 'Дуудлагын мэдээлэл',
        feedBack: 'Санал хүсэлт',
        connectedAccounts: 'Холбогдсон харилцагч',
        changePasswords: 'Нууц үг солих',
        recentDevices: 'Нэвтэрсэн төхөөрөмжүүд',
        userRequest: 'Мэдээлэл засах хүсэлтүүд',
        profile: 'Профайл',
        ebarimtHistory: 'НӨАТ баримтын түүх',
        logout: 'Системээс гарах',
      },
      billing:{
        bill_owner: "Төлөгч:",
        payInvoice: "Төлбөр төлөх",
        payCustomAmount: "Өөр дүнгээр төлөх",
        unPaidInvoiceList: "Төлөгдөөгүй нэхэмжлэх ",
        customerNumber: 'Хд',
        invoice: 'Нэхэмжлэх',
        invoiceNbr: 'Нэхэмжлэхийн дугаар',
        paid:  'Төлөгдсөн',
        unpaid:  'Төлөгдөөгүй',
        all: 'Нийт нэхэмжлэх',
        refund: "Төлөлт",
        pay: "Төлөх",
        currentBillValue: "Төлбөрийн үлдэгдэл: {{value}}",
        billingInfo: "Хугацаанд нь төлбөрөө төлөх нь конторын үйл ажиллагааг хэвийн явуулахад оруулж буй таны хувь нэмэр юм.",
        unPaidBillCount: "Үлдэгдэлтэй {{count}} нэмэмжлэх байна",
        payOthers: "Бусдын төлбөр төлөх",
        status: 'Төлөв',
        invoiceCount: "Нийт {{count}} нэхэмжлэх байна",
        totalBalance: "Нийт үлдэгдэл",
        balance: "Үлдэгдэл",
        payAmount: "Төлөх дүн",
        totalPayAmount: "Нийт төлөх дүн",
        paymentSummary: "Төлбөрийн мэдээлэл",
        selectedInvoiceCount: "Сонгогдсон нэхэмжлэхийн тоо",
        choosePaymentMethod: "Төлбөрийн хэрэгсэл сонгох",
        bill_issued_date: "Нэхэмжилсэн огноо",
        due_date: "Төлөх огноо",
        slot_kod: "Слот",
        item_kod: "Нэгж",
        descriptions:"Тайлбар",
        amount: "Дүн",
        thisMonthAmount: "Энэ сарын төлбөр:",
        noat: "НӨАТ:",
        overPay: "Илүү төлөлт:",
        totalAmount: "Нийт дүн:",
        pleaseEnterAmount: "Төлөх дүнгээ оруулна уу!",
        pleaseChooseBills: "Төлөх нэхэмжлэхээ сонгоно уу!",
        pleaseChooseBillsFromStart: "Нэхэмжлэх сонгохдоо хамгийн бага огноотойгоос эхлэн дараалалаар нь сонгоно уу!",
        paymentPaidSuccess: "Төлөлт амжилттай хийгдлээ",
        thankYouForPaid: "Төлбөрөө төлсөнд баярлалаа",
        transactionList: "Гүйлгээний жагсаалт",
        noBalance: 'Төлбөрийн үлдэгдэл байхгүй байна',
      },
      payment: {
        paymentMethod: "Төлбөрийн хэрэгсэл",
        date: "Огноо",
        invoiceId: "Гүйлгээний дагаар",
        status: "Төлөв",
        comment: "Гүйлгээний утга",
        accountNbr: "Хүлээн авах данс",
        accountName:"Хүлээн авагч",
      },
      ebarimt:{
        noat: "НӨАТ-ийн баримт",
        date: "Огноо",
        receiptNbr: "Баримт №",
        lottery: "Сугалаа",
        amount: "Дүн",
        showDetails: "Баримт үзэх",
      },
      customer:{
        basicInfo: 'Ерөнхий мэдээлэл',
        cellPhones: 'Утасны дугаар',
        emailAddress: 'И-мэйл хаяг',
        phoneNumber: 'Утасны дугаар',
        relationType: 'Холбоо хамаарал',
        name: 'Нэр',
        customerNumber: 'Харилцагчийн дугаар',
        district: 'Дүүрэг',
        khoroo: 'Хороо',
        village: 'Хотхон',
        house_nbr: 'Байрны дугаар',
        entrance_nbr: 'Орц дугаар',
        door_nbr: 'Хаалганы дугаар',
        owner_name: 'Эзэмшигчийн нэр',
        customer_type: 'Харилцагчийн төрөл',
        editInfo: 'Мэдээлэл засах',
        infoUpdateRequest: 'Мэдээлэл засах хүсэлт',
        userRequestType: 'Хүсэлтийн төрөл',
        userRequestPhone: 'Утасны дугаар засах хүсэлт',
        userRequestEmail: 'И-мэйл хаяг засах хүсэлт',
      },
      kontor: {
        kontor: 'Контор:',
        billingOrg: "Нэхэмжлэгч байгууллага:",
        name: "Нэр:",
        address: "Хаяг:",
        phone: "Утасны дугаар:"
      },
      customerAddress: "Харилцагчийн хаяг",
      continue: "Үргэлжлүүлэх",
      browser: 'Хөтөч',
      device: 'Төхөөрөмж',
      ipAddress: 'IP хаяг',
      loggedAt: 'Нэвтэрсэн огноо',
      barimt: "Баримт",
      msg:{
        youLoggedInSuccessfully: "Амжилттай нэвтэрлээ",
        passwordResetEmailSent: "Та имэйл хаягаа шалгана уу",
        youLogoutSuccessfully: "Системээс гарлаа",
        requestSend: "Хүсэлт илгээлээ",
      },
      warnings:{
        phoneRequirements: "Утасны дугаар талбарт 8 оронтой тоон утга оруулна.",
        emailRequirements: "И-мэйл хаяг талбарыг заавал бөглөнө.",
        relationTypeRequirements: "Холбоо хамаарал талбарыг заавал бөглөнө.",
      },
      validations:{
        emailNotValid: "Имэйл хаяг буруу байна",
        emailIsRequired: "Имэйл хаяг оруулна уу",
        loginNameIsRequired: "Нэвтрэх нэрээ оруулна уу",
        passwordIsRequired: "Нууц үгээ оруулна уу",
        currentPasswordIsRequired: "Одоогийн нууц үгээ оруулна уу",
        newPasswordIsRequired: "Шинэ нууц үгээ оруулна уу",
        passwordMinLength: "Нууц үг хамгийн багадаа 3 тэмдэгт байна",
      },
      header:{
        invoiceList: "Нэхэмжлэх ({{count}})",
        invoiceNumber: "Нэхэмжлэх №",
        amount: "Дүн",
        balance: "Үлдэгдэл",
        targetDate: "Огноо"
      },
      action: "Үйлдэл",
      addInfo: "Мэдээлэл нэмэх",
      details: "Дэлгэрэнгүй",
      billDetailsTitle: "Төлбөрийн задаргаа",
      info: "Мэдээлэл",
      CRM: "CRM",
      Shop: "Shop",
      Chat: "Chat",
      Sales: "Sales",
      Results: "Results",
      Invoice: "Invoice",
      Payment: "Payment",
      Profile: "Profile",
      Account: "Account",
      Pricing: "Pricing",
      Checkout: "Checkout",
      Sessions: "Sessions",
      Ecommerce: "Ecommerce",
      Dashboards: "Dashboards",
      
      "Enrolled Courses": "Enrolled Courses",
      "Course Completed": "Course Completed",
      "Course in Progress": "Course in Progress",
      "Learning Management": "Learning Management",
      "Welcome Back! Watson": "Welcome Back! Watson",
      "Job Management": "Job Management",
      "User & Contact": "User & Contact",
      "User List": "User List",
      "User Grid": "User Grid",
      "Contact List": "Contact List",
      "Contact Grid": "Contact Grid",
      "Invoice List": "Invoice List",
      "Invoice Details": "Invoice Details",
      "Create Invoice": "Create Invoice",
      "Product Details": "Product Details",
      "Billing Address": "Billing Address",
      "Payment Complete": "Payment Complete",
      "Admin Ecommerce": "Admin Ecommerce",
      "Product List": "Product List",
      "Product Grid": "Product Grid",
      "Create Product": "Create Product",
      "Upcoming Task": "Upcoming Task",
      "Study Time Last Week": "Study Time Last Week",
      "Your Downloads": "Your Downloads",
      "Course Status": "Course Status",
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});
