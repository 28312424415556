import axios from "axios";
import { URI, AccessTokenConst, RefreshTokenConst } from "../constants";
axios.defaults.baseURL = process.env.REACT_APP_HOSTED_DOMAIN;
let subscribers: any[] = [];
//main
const axiosInstance = axios.create({
  headers: {'Content-Type': 'application/json'},
});
//only retry
const retyApi = axios.create({
  headers: {'Content-Type': 'application/json'},
});

function onAccessTokenFetched(access_token: string) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(AccessTokenConst);
    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
let isRefreshing = false;
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if(error.response.config && error.response.config.url  && error.response.config.url.includes("auth/login")){
      return Promise.reject(
        (error.response && error.response.data) || "Something went wrong"
      )
    }
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = localStorage.getItem(RefreshTokenConst);
        retyApi
          .post(URI.REFRESH, {
            refresh_token: refreshToken,
          })
          .then((res) => {
            localStorage.setItem(AccessTokenConst, res.data.accessToken);
            localStorage.setItem(RefreshTokenConst, res.data.refreshToken);
            onAccessTokenFetched(res.data.accessToken);
          })
          .catch((e) => {
            console.log(e);
            localStorage.removeItem(AccessTokenConst);
            localStorage.removeItem(RefreshTokenConst);
          })
          .finally(() => {
            isRefreshing = false;
          });
      }
      let retryRequest = new Promise((resolve) => {
        let originalRequest = error.config;
        addSubscriber((access_token: any) => {
          originalRequest.headers.Authorization = access_token;
          resolve(axiosInstance.request(originalRequest));
        });
      });
      return retryRequest;
    } else if (
      error !== null &&
      error !== undefined &&
      error.toString().includes('401')
    ) {
      try {
        localStorage.removeItem(AccessTokenConst);
        localStorage.removeItem(RefreshTokenConst);
      } catch (e) {
        console.log(e);
      }
    }

    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
  }
);

export default axiosInstance;
