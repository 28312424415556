import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { FC, Fragment, useState } from "react";
import { Outlet } from "react-router";
import GuestHeader from "../layout-parts/GuestHeader";
const DashboardLayout: FC = ({ children }) => {
  const [sidebarCompact] = useState(false);
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const customStyle: CSSProperties = {
    width: `calc(100% - ${sidebarCompact ? "0px" : "0px"})`,
    marginLeft: sidebarCompact ? "0px" : "0px",
  };

  const renderContent = () => {
    //   IF MOBILE
    if (downLg) {
      return (
        <Grid item md={12} xs={12}>
          {children || <Outlet />}
        </Grid>
      );
    }
    return (
      <>
        <Grid item md={1} xs={12}></Grid>
        <Grid item md={10} xs={12}>
          <Box pt={2} pb={4}>
            {children || <Outlet />}
          </Box>
        </Grid>
        <Grid item md={1} xs={12}></Grid>
      </>
    );
  };
  return (
    <Fragment>
      <LayoutBodyWrapper sx={customStyle}>
        <GuestHeader
          setShowSideBar={() => {}}
          setShowMobileSideBar={() => {}}
        />
        <Box pt={2} pb={4}>
          <Grid container spacing={3}>
            {renderContent()}
          </Grid>
        </Box>
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
