import { Theme } from "@mui/material";

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const lightTheme = (theme: Theme) => theme.palette.mode === "light";

export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;
export const AccessTokenConst = "accessToken";
export const RefreshTokenConst = "refreshToken";
export const PaymentStatus = {
  PAYMENT_STATUS_CREATED: 0,
  PAYMENT_STATUS_FINTECH_PAID: 1,
  PAYMENT_STATUS_CONFIRMED: 5,
  PAYMENT_STATUS_CANCELLED: 9,
}
export const PaymentStatusNames = [
  {
    status: PaymentStatus.PAYMENT_STATUS_CONFIRMED,
    name: "Баталгаажсан"
  },
  {
    status: PaymentStatus.PAYMENT_STATUS_FINTECH_PAID,
    name: "Төлөгдсөн"
  },
  {
    status: PaymentStatus.PAYMENT_STATUS_CREATED,
    name: "Хүлээгдэж буй"
  },
  {
    status: PaymentStatus.PAYMENT_STATUS_CANCELLED,
    name: "Цуцлагдсан"
  }
]
export const PaymentSteps = {
  AMOUNT: 1,
  PAYMENT_METHOD: 2,
}

export const PaymenTypes = {
  PAYMENT_TYPE_BILLS: 1,
  PAYMENT_TYPE_INPUT: 2,
  PAYMENT_TYPE_OTHER: 3,
  PAYMENT_TYPE_GUEST: 4
}

export const PaymentMethodTypes = {
  QPAY: 1,
  MONPAY: 2,
  SOCIALPAY: 3,
  MOSTMONEY: 4,
  TRANSFER: 5,
}
  
export const PaymentMethodList = [
  {
    type: PaymentMethodTypes.QPAY,
    category: "Банкны аппликейшн ашиглан уншуулна",
    howToUse: "Банкны аппликейшн ашиглан уншуулна",
    name: "QPay",
    icon: "qpay.webp",
  },
  {
    type: PaymentMethodTypes.MONPAY,
    category: "Цахим төлбөр тооцооны апп",
    howToUse: "Monpay аппликейшн ашиглан уншуулна",
    name: "MONPAY",
    icon: "monpay.webp",
  },
  {
    type: PaymentMethodTypes.SOCIALPAY,
    category: "SocialPay Дижитал хэтэвч",
    howToUse: "SocialPay аппликейшн ашиглан уншуулна",
    name: "SocialPay",
    icon: "socialpay.webp",
  },
  {
    type: PaymentMethodTypes.MOSTMONEY,
    category: "Most Money цахим төлбөр тооцооны апп",
    howToUse: "Most Money аппликейшн ашиглан уншуулна",
    name: "Most Money",
    icon: "most_money.webp",
  },
  {
    type: PaymentMethodTypes.TRANSFER,
    category: "Гүйлгээний утгыг бичин дансруу шилжүүлэх",
    howToUse: null,
    name: "Дансаар шилжүүлэх",
    icon: "banktransfer.svg",
  }
];

export const URI = {
  LOGIN: "v1/auth/login",
  FORGOT_PASSWORD: "v1/password/forgot",
  CHANGE_PASSWORD: "v1/users/change_password",
  REFRESH: "v1/auth/refresh_token",
  PROFILE: "v1/users/profile",
  CONTACT_INFO: "v1/users/contact_info",
  USER_REQUESTS: "v1/user_requests",
  USER_CUSTOMERS: "v1/user_customers",
  DASHBOARD: "v1/bills/dashboard",
  BILLS: "v1/bills",
  GUEST_BILL: "v1/bills/guest_show/",
  DEVICES: "v1/devices",
  PAYMENTS: "/v1/payments",
  EBARIMT_HISTORIES: "/v1/ebarimt_histories",
  GUEST_PAYMENTS: "/v1/payments/guest",
  PAYMENT_STATUS_CHECK: "/v1/payments/check"
};
