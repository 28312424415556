import { Box, Button, Card, Divider, Grid, Stack, styled } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { H5, H6, Small, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";
import { userServices } from "services";
const Dot = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  flexShrink: 0,
  borderRadius: "50%",
  backgroundColor: theme.palette.text.secondary,
}));

const Password: FC = () => {
  const { t } = useTranslation();
  const { passwordChanged } = useAuth();
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string()
      .min(3, t("validations.passwordMinLength"))
      .required(t("validations.currentPasswordIsRequired")),
    newPassword: Yup.string()
      .min(8, t("passwordRequirements.minimumCharactersLong"))
      .required(t("validations.newPasswordIsRequired")),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      t("passwordDoesntmatched")
    ),
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      userServices
        .changePassword({
          current_password: values.currentPassword,
          new_password: values.newPassword,
          confirm_password: values.confirmNewPassword,
        })
        .then((res) => {
          //update user in context
          resetForm();
          passwordChanged();
        })
        .catch((error: any) => {
          if (error.error) {
            toast.error(error.error.message);
          } else {
            toast.error(error.message);
          }
        });
    },
  });

  return (
    <Card>
      <H5 padding={3}>{t("menu.changePasswords")}</H5>
      <Divider />

      <Box padding={3}>
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <AppTextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  name="currentPassword"
                  label={t("currentPassword")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.currentPassword}
                  helperText={touched.currentPassword && errors.currentPassword}
                  error={Boolean(
                    touched.currentPassword && errors.currentPassword
                  )}
                />

                <AppTextField
                  fullWidth
                  type="password"
                  name="newPassword"
                  variant="outlined"
                  label={t("newPassword")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.newPassword}
                  helperText={touched.newPassword && errors.newPassword}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                />
                <AppTextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  name="confirmNewPassword"
                  label={t("confirmPassword")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmNewPassword}
                  helperText={
                    touched.confirmNewPassword && errors.confirmNewPassword
                  }
                  error={Boolean(
                    touched.confirmNewPassword && errors.confirmNewPassword
                  )}
                />
              </Stack>

              <Stack direction="row" spacing={3} mt={4}>
                <Button type="submit" variant="contained">
                  {t("save")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    resetForm();
                  }}
                >
                  {t("clear")}
                </Button>
              </Stack>
            </form>
          </Grid>

          <Grid item sm={6} xs={12}>
            <H6>{t("passwordChangeInfo")}</H6>
            <Tiny lineHeight={1.7}>{t("passwordRequirementsAreMet")}</Tiny>

            <Stack spacing={1} mt={2}>
              <FlexBox alignItems="center">
                <Dot mr={1} />
                <Small fontSize={12} color="text.secondary">
                  {t("passwordRequirements.minimumCharactersLong")}
                </Small>
              </FlexBox>

              <FlexBox alignItems="center">
                <Dot mr={1} />
                <Small fontSize={12} color="text.secondary">
                  {t("passwordRequirements.atLeastOneLowercaseCharacter")}
                </Small>
              </FlexBox>

              <FlexBox alignItems="center">
                <Dot mr={1} />
                <Small fontSize={12} color="text.secondary">
                  {t("passwordRequirements.atLeastOneUppercaseCharacter")}
                </Small>
              </FlexBox>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default Password;
