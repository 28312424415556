import React from 'react';
import { format } from "date-fns";
export const searchByName = (listData: any[], searchValue: string) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item) =>
      item.name.toLocaleLowerCase().match(searchValue.toLowerCase())
    );
    return searchResult;
  } else {
    return listData;
  }
};

export const getRoute = (pathname: string) => {
  const str = pathname.split("/");
  return `/${str[1]}`;
};

export const moneyFormat = (amount: string) => {
  return Number(amount).toLocaleString("en-US", { minimumFractionDigits: 2 })+" ₮";
};

export const parseRes = (item: any) => {
  if (item && item.attributes) {
    return item.attributes;
  }
  if (item && item.data && item.data.attributes) {
    return item.data.attributes;
  }
  return item;
};

export const createTargetId = () => {
  return Number(new Date());
}

export const validateEmail = (value: string) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return value.match(validRegex);
};

export const simpleDateFormat=(date: string)=>{
  return format(new Date(date), 'yyyy/MM/dd')
}
export const customerAddress=(data: any)=>{
 let address = "";
 if(data){
  if(data.district){
    address = data.district;
  }
  if(data.khoroo){
    address = address + ", " + data.khoroo+"-р хороо";
  }
  if(data.village){
    address = address + ", " + data.village;
  }
  if(data.house_nbr){
    address = address + ", " + data.house_nbr;
  }
  if(data.entrance_nbr){
    address = address + ", " + data.entrance_nbr;
  }
  if(data.door_nbr){
    address = address + ", " + data.door_nbr;
  }
 }
 return address
}

export const useInterval = (callback: Function, delay: number | null) => {
  const savedCallback: React.MutableRefObject<any>  = React.useRef();
  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      if(savedCallback){
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}