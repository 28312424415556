export const DASHBOARD_SCREENS = {
  DASHBOARDS: "/dashboards/",
  HOME: "/",
  PROFILE: "profile",
  PAYMENTS:  "payments/",
  PAYMENT_HISTORY:  "payments/history",
  CUSTOMERS: "customers",
  DEVICES: "devices",
  PASSOWRD: "password",
  USER_REQUESTS: "userrequests",
  INVOICE: "invoice/",
  UNDER_MAINTENANCE: 'maintenance',
  EBARIMTS: 'ebarimts'
};