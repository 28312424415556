import axios from "../utils/axios";
import { URI } from "../constants";
export const userServices = {
  forgotPassword: (payload: any) => {
    return axios.post(URI.FORGOT_PASSWORD, payload);
  },
  changePassword: (payload: any) => {
    return axios.post(URI.CHANGE_PASSWORD, payload);
  },
  getUserCustomers: () => {
    return axios.get(URI.USER_CUSTOMERS);
  },
  addUserCustomer:(payload: any)=>{
    return axios.post(URI.USER_CUSTOMERS, payload);
  },
  deleteUserCustomer:(id: any)=>{
    return axios.delete(URI.USER_CUSTOMERS + "/" + id);
  },
  getContacts: () => {
    return axios.get(URI.CONTACT_INFO);
  },
  getDevices: () => {
    return axios.get(URI.DEVICES);
  },
}