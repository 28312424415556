import DeleteOutlined from "icons/DeleteOutlined";
import DevicesApple from "icons/DevicesApple";
import DollarOutlined from "icons/DollarOutlined";
import FileOutlined from "icons/FileOutlined";
import Fingerprint from "icons/Fingerprint";
import Instagram from "icons/Instagram";
import Key from "icons/Key";
import Link from "icons/Link";
import LockOutlined from "icons/LockOutlined";
import NotificationOutlined from "icons/NotificationOutlined";
import PremiumOutlined from "icons/PremiumOutlined";
import SettingsOutlined from "icons/SettingsOutlined";
import UserOutlined from "icons/UserOutlined";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  NotificationOutlined,
  DeleteOutlined,
  DevicesApple,
  DollarOutlined,
  FileOutlined,
  Link,
  Key,
  LockOutlined,
  PremiumOutlined,
  SettingsOutlined,
  UserOutlined,
  Fingerprint,
  Instagram,
};
