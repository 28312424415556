import { SvgIcon, SvgIconProps } from "@mui/material";

const Fingerprint = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M5.75,11.75C5.75,16.58,8,19.15,8,19.6A.49.49,0,0,1,7.8,20l-.42.28a.47.47,0,0,1-.28.09c-.38,0-1.08-1.08-1.7-2.68a17.32,17.32,0,0,1-1.15-5.84V10A7.87,7.87,0,0,1,12,2a8.11,8.11,0,0,1,1.21.09.49.49,0,0,1,.35.24.5.5,0,0,1,0,.43l-.18.48a.49.49,0,0,1-.54.33A5.14,5.14,0,0,0,12,3.5c-4.91,0-6.21,4.7-6.21,6.43ZM15.7,3a.51.51,0,0,0-.42,0,.51.51,0,0,0-.29.3l-.18.49a.5.5,0,0,0,.22.6,6.19,6.19,0,0,1,3.15,5.39v1.65a.5.5,0,0,0,.5.5h.5a.51.51,0,0,0,.5-.5V9.71A7.74,7.74,0,0,0,15.7,3ZM12.25,9.75h-.5a.5.5,0,0,0-.5.5v1.5a8.48,8.48,0,0,0,.57,3.06.49.49,0,0,0,.29.29.51.51,0,0,0,.42,0l.43-.24a.49.49,0,0,0,.23-.61,7.06,7.06,0,0,1-.44-2.47v-1.5A.5.5,0,0,0,12.25,9.75Zm4.82,8.64a7.08,7.08,0,0,1-2.73-2.13.5.5,0,0,0-.63-.13l-.45.25a.5.5,0,0,0-.16.74,8.72,8.72,0,0,0,3.42,2.66.5.5,0,0,0,.66-.29l.18-.48A.49.49,0,0,0,17.07,18.39ZM9.25,11.93a.47.47,0,0,0-.5-.43h-.5a.5.5,0,0,0-.5.46v0c.13,6,4.74,9.89,5.53,9.89a.5.5,0,0,0,.43-.24l.27-.42a.48.48,0,0,0,.07-.26C14.05,20.35,9.34,18.14,9.25,11.93Zm9.31,3.28a3.76,3.76,0,0,1-2.31-3.46V9.94A4.37,4.37,0,0,0,12,5.5a4.26,4.26,0,0,0-4.24,4v0a.49.49,0,0,0,.5.48h.51a.5.5,0,0,0,.5-.48,2.74,2.74,0,0,1,5.48.23v2a5.13,5.13,0,0,0,3.51,4.92.5.5,0,0,0,.48-.37l.13-.48a.32.32,0,0,0,0-.13A.53.53,0,0,0,18.56,15.21Z" />
    </SvgIcon>
  );
};

export default Fingerprint;
