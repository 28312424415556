import { Box, Card } from "@mui/material";
import { FC } from "react";
import SideBarItems from "./SideBarItems";

const SideBarMenu: FC = () => {
  return (
    <Box pt={2} pb={4}>
      <Card sx={{ padding: "1rem 0" }}>
        <SideBarItems isMobile={false} />
      </Card>
    </Box>
  );
};

export default SideBarMenu;
