import axios from "../utils/axios";
import { URI } from "../constants";
export const billingServices = {
  getDashboard: (payload: any) => {
    return axios.get(URI.DASHBOARD, { params: payload });
  },
  getBills: (payload: any) => {
    return axios.get(URI.BILLS, { params: payload });
  },
  getGuestBill: (invoiceNbr: string) => {
    return axios.get(URI.GUEST_BILL+invoiceNbr);
  },
  getBillDetails: (id: any) => {
    return axios.get(URI.BILLS + "/" + id);
  },
  payBills: (isGuest: boolean,payload: any)=>{
    if(isGuest){
      return axios.post(URI.GUEST_PAYMENTS, payload);  
    }
    return axios.post(URI.PAYMENTS, payload);
  },
  paymentStatusCheck: (payload: any)=>{
    return axios.post(URI.PAYMENT_STATUS_CHECK, payload);
  },
  getPaymentHistory: (year: string, paymentStatusFilter: number) => {
    return axios.get(URI.PAYMENTS + "?year=" + year + "&status=" + paymentStatusFilter);
  },
  getEbarimtHistories: (payload: any) => {
    return axios.get(URI.EBARIMT_HISTORIES, { params: payload });
  },
};
