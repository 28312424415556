import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, FormHelperText } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import AppTextField from "components/input-fields/AppTextField";
import { Small } from "components/Typography";
import AuthenticationLayout from "page-sections/authentication/AuthenticationLayout";
import FlexBox from "components/flexbox/FlexBox";
import { useFormik } from "formik";
import useAuth from "hooks/useAuth";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AppCheckBox from "components/AppCheckBox";
import { useTranslation } from "react-i18next";
const Login: FC = () => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const loginNameKey = "loginName";
  const savedLoginName = localStorage.getItem(loginNameKey);
  let navigate = useNavigate();
  const initialValues = {
    loginName: savedLoginName ? savedLoginName : "",
    password: "",
    remember: true,
    submit: null,
  };
  // form field value validation schema
  const validationSchema = Yup.object().shape({
    loginName: Yup.string()
      .max(255)
      .required(t("validations.loginNameIsRequired")),
    password: Yup.string()
      .min(3, t("validations.passwordMinLength"))
      .required(t("validations.passwordIsRequired")),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        setLoading(true);
        login(values.loginName, values.password)
          .then(() => {
            setLoading(false);
            if (values.remember) {
              localStorage.setItem(loginNameKey, values.loginName);
            }
            toast.success(t("msg.youLoggedInSuccessfully"));
            navigate("/dashboards");
          })
          .catch((error) => {
            if (error.error) {
              setError(error.error.message);
            } else {
              setError(error.message);
            }
            setLoading(false);
          });
      },
    });

  return (
    <AuthenticationLayout
      route="/login"
      description="Хэрэглэгчийн цахим систем"
      title="Смарт Контор"
      routeName=""
    >
      <form noValidate onSubmit={handleSubmit}>
        <Stack gap={2} mt={5}>
          <AppTextField
            fullWidth
            name="loginName"
            label={t("loginName")}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.loginName || ""}
            error={Boolean(touched.loginName && errors.loginName)}
            helperText={touched.loginName && errors.loginName}
            sx={{ mb: { xs: 1 } }}
          />
          <AppTextField
            fullWidth
            name="password"
            type="password"
            label={t("password")}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password || ""}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <FlexBetween>
            <FlexBox alignItems="center" gap={1}>
              <AppCheckBox
                id="remember"
                checked={values.remember}
                onChange={handleChange}
              />
              <Small fontSize={12}>{t("rememberMe")}</Small>
            </FlexBox>

            <Button
              disableRipple
              sx={{ color: "error.main", mb: 2 }}
              href="/forget-password"
            >
              {t("forgotPassword")}
            </Button>
          </FlexBetween>
        </Stack>

        {error && (
          <FormHelperText
            error
            sx={{
              mt: 2,
              fontSize: 13,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {error}
          </FormHelperText>
        )}

        <Box sx={{ mt: 4 }}>
          {loading ? (
            <LoadingButton loading fullWidth variant="contained">
              {t("signIn")}
            </LoadingButton>
          ) : (
            <Button fullWidth type="submit" variant="contained">
              {t("signIn")}
            </Button>
          )}
        </Box>
      </form>
    </AuthenticationLayout>
  );
};

export default Login;
