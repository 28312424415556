import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { FC, Fragment, useState } from "react";
import { Outlet } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import SideBarMenu from "./SideBarMenu";
const DashboardLayout: FC = ({ children }) => {
  const [sidebarCompact, setSidebarCompact] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);
  const handleCompactToggle = () => setSidebarCompact(!sidebarCompact);
  const handleMobileDrawerToggle = () =>
    setShowMobileSideBar((state) => !state);
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const customStyle: CSSProperties = {
    width: `calc(100% - ${sidebarCompact ? "0px" : "0px"})`,
    marginLeft: sidebarCompact ? "0px" : "0px",
  };

  const renderContent = () => {
    //   IF MOBILE
    if (downLg) {
      return (
        <Grid item md={12} xs={12}>
          {children || <Outlet />}
        </Grid>
      );
    }
    return (
      <>
        <Grid item md={3} xs={12}>
          <SideBarMenu />
        </Grid>
        <Grid item md={9} xs={12}>
          <Box pt={2} pb={4}>
            {children || <Outlet />}
          </Box>
        </Grid>
      </>
    );
  };
  return (
    <Fragment>
      <DashboardSidebar
        sidebarCompact={sidebarCompact}
        showMobileSideBar={showMobileSideBar}
        setSidebarCompact={handleCompactToggle}
        setShowMobileSideBar={handleMobileDrawerToggle}
      />

      <LayoutBodyWrapper sx={customStyle}>
        <DashboardHeader
          setShowSideBar={handleCompactToggle}
          setShowMobileSideBar={handleMobileDrawerToggle}
        />
        <Box pt={2} pb={4}>
          <Grid container spacing={3}>
            {renderContent()}
          </Grid>
        </Box>
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
