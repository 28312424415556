import { Button, styled, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBox from "components/flexbox/FlexBox";
import Icons from "icons/account";
import duotone from "icons/duotone";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DASHBOARD_SCREENS } from "screen-paths";
import useAuth from "hooks/useAuth";
// styled component
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  position: "relative",
  padding: "0.6rem 1.5rem",
  justifyContent: "flex-start",
  fontWeight: 500,
  color: theme.palette.text.primary,
}));

interface SidebarProps {
  isMobile: boolean;
  onClose?: Function;
}

const SideBarItems: FC<SidebarProps> = (props) => {
  const { isMobile, onClose } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout } = useAuth();
  // handle active current page
  const activeRoute = (path: string | undefined) => {
    if (path === DASHBOARD_SCREENS.HOME && "/dashboards" === pathname) {
      return true;
    }
    if (path) {
      if (path.includes(DASHBOARD_SCREENS.UNDER_MAINTENANCE)) {
        return false;
      }
    }
    return pathname === DASHBOARD_SCREENS.DASHBOARDS + path ? true : false;
  };

  const handleNavigation = (path: string) => {
    if (isMobile && onClose) {
      onClose();
    }
    navigate(path);
  };

  const style = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary[100],
    "&::before": {
      left: 0,
      width: 4,
      content: '""',
      height: "100%",
      borderRadius: 4,
      position: "absolute",
      transition: "all 0.3s",
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary[100],
    },
  };

  const menuList = [
    {
      id: 0,
      name: t("menu.dashboard"),
      Icon: duotone.Dashboard,
      path: DASHBOARD_SCREENS.UNDER_MAINTENANCE,
    },
    {
      id: 1,
      name: t("menu.invoices"),
      Icon: duotone.Invoice,
      path: DASHBOARD_SCREENS.HOME,
    },
    {
      id: 9,
      name: t("menu.ebarimtHistory"),
      Icon: duotone.DataTable,
      path: DASHBOARD_SCREENS.EBARIMTS,
    },
    {
      id: 11,
      name: t("menu.onlinePayment"),
      Icon: Icons.DollarOutlined,
      path: DASHBOARD_SCREENS.PAYMENT_HISTORY,
    },
    {
      id: 12,
      name: t("menu.counterInfo"),
      Icon: duotone.ElementHub,
      path: DASHBOARD_SCREENS.UNDER_MAINTENANCE,
    },
    {
      id: 13,
      name: t("menu.waterInfo"),
      Icon: duotone.TableList,
      path: DASHBOARD_SCREENS.UNDER_MAINTENANCE,
    },
    {
      id: 14,
      name: t("menu.callingInfo"),
      Icon: duotone.LayerGroup,
      path: DASHBOARD_SCREENS.UNDER_MAINTENANCE,
    },

    {
      id: 2,
      name: t("menu.profile"),
      Icon: Icons.UserOutlined,
      path: DASHBOARD_SCREENS.PROFILE,
    },
    {
      id: 4,
      name: t("menu.connectedAccounts"),
      Icon: Icons.Link,
      path: DASHBOARD_SCREENS.CUSTOMERS,
    },
    {
      id: 5,
      name: t("menu.changePasswords"),
      Icon: Icons.SettingsOutlined,
      path: DASHBOARD_SCREENS.PASSOWRD,
    },
    {
      id: 51,
      name: t("menu.feedBack"),
      Icon: Icons.FileOutlined,
      path: DASHBOARD_SCREENS.UNDER_MAINTENANCE,
    },
    {
      id: 6,
      name: t("menu.recentDevices"),
      Icon: Icons.DevicesApple,
      path: DASHBOARD_SCREENS.DEVICES,
    },
    {
      id: 55,
      name: t("menu.logout"),
      Icon: Icons.LockOutlined,
      path: "logout",
    },
  ];

  function ListContent() {
    return (
      <FlexBox flexDirection="column">
        {menuList.map(({ id, name, Icon, path }) => {
          return (
            <StyledButton
              key={id}
              onClick={() => {
                if (path === "logout") {
                  logout();
                  navigate("/login");
                } else if (path) {
                  handleNavigation(path);
                }
              }}
              startIcon={<Icon sx={{ color: "text.secondary" }} />}
              sx={activeRoute(path) ? style : { "&:hover": style }}
            >
              {t(name)}
            </StyledButton>
          );
        })}
      </FlexBox>
    );
  }

  return <ListContent />;
};

export default SideBarItems;
