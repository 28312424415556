import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { FC, Fragment, useState } from "react";
import { Outlet } from "react-router";
const PasswordLayout: FC = ({ children }) => {
  const [sidebarCompact] = useState(false);
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const customStyle: CSSProperties = {
    width: `calc(100% - ${sidebarCompact ? "0px" : "0px"})`,
    marginLeft: sidebarCompact ? "0px" : "0px",
  };

  const renderContent = () => {
    //   IF MOBILE
    if (downLg) {
      return (
        <Grid item md={12} xs={12}>
          {children || <Outlet />}
        </Grid>
      );
    }
    return (
      <>
        <Grid item md={2} xs={2} />
        <Grid item md={8} xs={8}>
          {children || <Outlet />}
        </Grid>
        <Grid item md={2} xs={2} />
      </>
    );
  };
  return (
    <Fragment>
      <LayoutBodyWrapper sx={customStyle}>
        <Box pt={4} pb={8}>
          <Grid container spacing={30}>
            {renderContent()}
          </Grid>
        </Box>
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default PasswordLayout;
